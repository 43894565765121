<template>
  <SubPageWrapper
    width="3xl"
    class="bill-of-materials"
  >
    <template #breadcrumbs>
      <SlBreadcrumb
        v-if="!isEntireBom"
        @click="handleGoBack"
      >
        {{ selectedEntryRouteLabel }}
      </SlBreadcrumb>
      <SlBreadcrumb
        v-else
        @click="handleGoToInventory"
      >
        {{ inventoryLabel }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ title }}
      </SlBreadcrumb>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <div class="subpage__actions-group">
          <SlDropdown
            :triggers="[]"
            placement="bottom-start"
            content-class="sl-tree-search-dropdown"
            no-auto-focus
          >
            <template #target="{ open }">
              <SlSearchInput
                v-model="search"
                class="w-280"
                :loading="searchLoading"
                @submit="(query) => handleSearch({ query, open })"
              />
            </template>
            <template #content>
              <SlTreeSearchOption
                v-for="node in foundNodes"
                :key="node.id"
                :item="node"
                @select="handleSelectFoundNode(node.id)"
              >
                {{ node.id }}
              </SlTreeSearchOption>
              <SlNoResultsOption v-if="!foundNodes.length" />
            </template>
          </SlDropdown>
          <SlSelect
            v-if="isLocationsVisible"
            v-model="locationModel"
            :options="locationItems"
            class="w-240"
          >
            <template #singleLabel>
              <span class="sl-select__label-key">
                {{ $t('EntireBom.Ui.lbLoc') }}:
              </span>
              <span class="sl-select__label-value">
                {{ locationValueLabel }}
              </span>
            </template>
          </SlSelect>
        </div>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleExportToXlsx"
        >
          {{ $t('Main.SaveXlsx') }}
        </SlButton>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="batchesOnHand"
            ref="batchesOnHand"
            :headers="tableHeaders"
            :rows="tableRows"
            unique-key="id"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            :max-height="1000"
            :value-parser="parseValue"
            :loading="loading"
            :initial-col-sizes="[400]"
            :highlighted-row-id="foundNodeId"
            expandable
            @contextmenu:clipboard="handleContextMenu"
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <template #Item="slotScope">
              <div
                class="sl-table__cell--expandable"
                :style="{
                  'padding-left': getRowPadding(slotScope)
                }"
              >
                <template v-if="slotScope.row && slotScope.row.expandable">
                  <icon
                    data="@icons/chevron_right.svg"
                    class="fill-off size-16 color-grey-60 sl-table__cell-chevron"
                    :class="{
                      'rotate-90': isRowExpanded(slotScope)
                    }"
                    @click="toggleRow(slotScope)"
                  />
                </template>
                <div class="sl-table__cell-text">
                  {{ slotScope.cellValue.val }}
                </div>
              </div>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { bomTypes, emptyBomLocation } from '@/config/item/bom.config';
import { routeNames } from '@/config/router/router.config';
import { bomTableClipboardMenuConfig } from '@/config/shared/clipboardContextMenu.config';

export default {
  name: 'BillOfMaterials',
  mixins: [fileSaver],
  data() {
    return {
      search: '',
      foundNodes: [],
      foundNodeId: null,
      loading: false,
      searchLoading: false,
      labelByRoute: {
        [routeNames.DEMAND]: this.$t('Main.tabNames.main'),
        [routeNames.INVENTORY]: this.$t('Main.tabNames.inventory'),
        [routeNames.INTERSTORE]: this.$t('Main.tabNames.transfers'),
        [routeNames.REPORTS]: this.$t('Main.tabNames.report')
      }
    };
  },
  computed: {
    ...mapState({
      table: state => state.bom.table || {},
      locations: state => state.bom.locations,
      openedNodes: state => state.bom.open_nodes,
      activeLocation: state => state.bom.active_location,
      selectedEntryRoute: state => state.bom.selected_entry?.route || routeNames.DEMAND
    }),
    tableHeaders() {
      return this.table.headers || [];
    },
    tableRows() {
      return this.table.rows || [];
    },
    title() {
      if (this.isEntireBom) {
        return this.$t('EntireBom.Ui.:Caption');
      }

      return this.$t('Main.Ui.acItemBom');
    },
    selectedEntryRouteLabel() {
      return this.labelByRoute[this.selectedEntryRoute];
    },
    inventoryLabel() {
      return this.labelByRoute[routeNames.INVENTORY];
    },
    bomType() {
      return this.$route.params.type;
    },
    // kostyl for empty locations
    // track by https://gmdhsoftware.atlassian.net/browse/DSL-79
    locationItems() {
      return this.locations.map(location => ({
        value: location || emptyBomLocation,
        label: location || this.$t('Main.OtherCat')
      }));
    },
    locationValueLabel() {
      return this.locationModel === emptyBomLocation
        ? this.$t('Main.OtherCat')
        : this.locationModel;
    },
    isEntireBom() {
      return this.bomType === bomTypes.ENTIRE;
    },
    isLocationsVisible() {
      return this.locationItems.length > 1;
    },
    locationModel: {
      get() {
        return this.activeLocation;
      },
      set(value) {
        this.setActiveLocation(value);
        this.fetchTable();
      }
    }
  },
  watch: {
    bomType() {
      this.fetchTable({ fetchLocations: true });
    }
  },
  beforeMount() {
    this.fetchTable({ fetchLocations: true });
  },
  beforeRouteLeave(to, from, next) {
    this.resetState();

    next();
  },
  methods: {
    ...mapActions({
      fetchBomLocations: 'bom/fetchBomLocations',
      fetchBomTree: 'bom/fetchBomTree',
      setActiveLocation: 'bom/setActiveLocation',
      fetchBomTreeNodes: 'bom/fetchBomTreeNodes',
      collapseBomNode: 'bom/collapseBomNode',
      fetchTreeSearch: 'bom/fetchTreeSearch',
      exportToXlsx: 'bom/exportToXlsx',
      resetState: 'bom/resetState'
    }),
    async fetchTable({ id, fetchLocations = false } = {}) {
      try {
        this.loading = true;

        if (fetchLocations) {
          await this.fetchBomLocations();
        }

        await this.fetchBomTree({
          type: this.bomType,
          id
        });
      } finally {
        this.loading = false;
      }
    },
    // table configuration
    parseValue(cellValue) {
      if (typeof cellValue === 'string') {
        return cellValue;
      }

      return cellValue.val ?? '';
    },
    isRowExpanded(scope) {
      return this.openedNodes.includes(scope.row?.id);
    },
    getRowPadding(scope) {
      return 8 + scope.row?.depth * 24 + 'px';
    },
    toggleRow(scope) {
      if (this.isRowExpanded(scope)) {
        return this.collapseBomNode({
          index: scope.rowIndex,
          depth: scope.row.depth,
          rowId: scope.row.id
        });
      }

      this.fetchBomTreeNodes({
        type: this.bomType,
        nodeId: scope.row.id,
        rowIndex: scope.rowIndex
      });
    },
    // actions
    async handleSearch({ query, open }) {
      if (!query) {
        return;
      }

      try {
        this.searchLoading = true;

        this.foundNodes = await this.fetchTreeSearch({
          type: this.bomType,
          query
        });

        open && open();
      } finally {
        this.searchLoading = false;
      }
    },
    async handleSelectFoundNode(id) {
      this.foundNodeId = id;
      this.search = '';

      await this.fetchTable({
        id
      });

      const index = this.tableRows.findIndex(row => row.id === id);

      this.$refs.batchesOnHand.scrollToIndex(index);
    },
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx.bind(this, this.bomType));
    },
    handleGoBack() {
      this.$router.push({
        name: this.selectedEntryRoute
      });
    },
    handleGoToInventory() {
      this.$router.push({
        name: routeNames.INVENTORY
      });
    },
    handleContextMenu(callback) {
      callback(bomTableClipboardMenuConfig(this));
    }
  }
};
</script>

<style lang="scss" scoped>
.content-block {
  .subpage__table-wrapper {
    height: calc(100% - 51px);
  }
}
</style>
